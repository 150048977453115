import React from 'react';
import { Input, IInputProps, IconType } from '@dealroadshow/uikit';

interface IProps extends IInputProps {
  placeholder?: string,
}

function PasswordInput(props: IProps) {
  return (
    <Input
      type="password"
      placeholder={ props.placeholder || 'Password' }
      iconType={ IconType.lock }
      { ...props }
    />
  );
}

export default PasswordInput;
