import { withUsersGlobalProps } from '@/users/application/withUsersGlobalProps';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import Layout from '@/users/ui/common/Layout';
import LoginComponent from '@/users/ui/components/Login';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';

export const getServerSideProps = withUsersGlobalProps(() => ({ props: {} }));

const Login = ({ tenantTheme }: { tenantTheme: ITenantTheme }) => (
  <Layout
    withBanner
    onInit={ useSessionContext().switchToPublicAccess }
    tenantTheme={ tenantTheme }
  >
    <LoginComponent />
  </Layout>
);

export default Login;
